@font-face {
  font-family: 'Helvetica';
  src: url('Helvetica.eot');
  src: url('Helvetica.eot?#iefix') format('embedded-opentype'),
    url('Helvetica.woff2') format('woff2'), url('Helvetica.woff') format('woff'),
    url('Helvetica.ttf') format('truetype'),
    url('Helvetica.svg#Helvetica') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica';
  src: url('HelveticaBold.eot');
  src: url('HelveticaBold.eot?#iefix') format('embedded-opentype'),
    url('HelveticaBold.woff2') format('woff2'),
    url('HelveticaBold.woff') format('woff'),
    url('HelveticaBold.ttf') format('truetype'),
    url('HelveticaBold.svg#HelveticaBold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
